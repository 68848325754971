import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router'; 
import { AllService } from '../../services/all.service';
import {  } from "../../models/all.model";
declare var $: any;
declare var CKEDITOR: any;

@Component({
  selector: 'app-createcontactus',
  templateUrl: './createcontactus.component.html',
  styleUrls: ['./createcontactus.component.css']
})
export class CreatecontactusComponent implements OnInit {

  tinymce: any;
  createForm: FormGroup;
  data: any;
  isUpdate: boolean;
  id: any;
  
  constructor(private allService: AllService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {
    this.createForm = this.fb.group({
     data: '',
     name: 'contactus'
      // skill_code: 'data'
    });
    
  }
  ngOnInit(): void { this.getInfo(); }
  getInfo() {
    this.allService.getContentList().subscribe(
      (response) => {
        var foundValue = response.data.filter(obj => obj.name == 'contactus');
        if (foundValue.length > 0) {
          this.isUpdate = true;
          this.id = foundValue[0].id;
          this.allService.getContent( this.id).subscribe(
            (response) => {
              this.renderEditor(response.data.data);
            });
        }
        else {
          this.isUpdate = false;
          this.renderEditor("");
        }
      });
  }

  renderEditor(data) {
    CKEDITOR.replace('editor', {
      skin: 'moono',
      enterMode: CKEDITOR.ENTER_BR,
      shiftEnterMode:CKEDITOR.ENTER_P,
      toolbar: [{ name: 'basicstyles', groups: [ 'basicstyles' ], items: [ 'Bold', 'Italic', 'Underline', "-", 'TextColor', 'BGColor' ] },
                 { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
                 { name: 'scripts', items: [ 'Subscript', 'Superscript' ] },
                 { name: 'justify', groups: [ 'blocks', 'align' ], items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                 { name: 'paragraph', groups: [ 'list', 'indent' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
                 { name: 'links', items: [ 'Link', 'Unlink' ] },
                 { name: 'insert', items: [ 'Image'] },
                 { name: 'spell', items: [ 'jQuerySpellChecker' ] },
                 { name: 'table', items: [ 'Table' ] }
                 ],
    });
    CKEDITOR.instances['editor'].setData(data);
  }

  createckData(data, name) {
    data = CKEDITOR.instances.editor.getData();
    if(this.isUpdate) {
      this.allService.updateContent( this.id, 'contactus', data).subscribe(() => {
        this.router.navigate(['/config/contactus']);
      });
    }
    else {
      this.allService.createckData(data, 'contactus').subscribe(() => {
        this.router.navigate(['/config/contactus']);
      });
    }
  }

}
