import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllService } from 'app/services/all.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  privacyData: any;
  showCreate: boolean = false;

  constructor(private allService: AllService, private router: Router) { }

  ngOnInit(): void {
    this.allService.getContentList().subscribe(
      (response) => {
        var foundValue = response.data.filter(obj => obj.name == 'privacy');
        this.allService.getContent(foundValue[0].id).subscribe(
          (response) => {
            this.privacyData = response.data.data;
          });
      });
    
      if (this.router.url.indexOf('/config/privacy') > -1) {
        this.showCreate = true;
      }
  }
}
