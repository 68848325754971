import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllService } from 'app/services/all.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  contactusData: any;
  showCreate: boolean = false;

  constructor(private allService: AllService, private router: Router) { }

  ngOnInit(): void {
    this.allService.getContentList().subscribe(
      (response) => {
        var foundValue = response.data.filter(obj => obj.name == 'contactus');
        this.allService.getContent(foundValue[0].id).subscribe(
          (response) => {
            this.contactusData = response.data.data;
          });
      });
    
      if (this.router.url.indexOf('/config/contactus') > -1) {
        console.log("hia");
        this.showCreate = true;
      }
  }

}
