import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models/user';
const AUTH_API = 'https://api.newsystemsolution.com/api/admin/login';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // selectedUser: User = {
  //   email: '',
  //   password: ''
  // };
  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  constructor(private http: HttpClient, private router: Router) { }


  login(authCredentials): Observable<any> {
    return this.http.post(AUTH_API , authCredentials,this.noAuthHeader);
    this.router.navigate(['dashboard'])
  }

    //Helper Methods

    setToken(token: string) {
      localStorage.setItem('token', token);
      console.log('Token set in localStorage:', token);
    }
  
    getToken() {
      const token = localStorage.getItem('token');
      console.log('Token retrieved from localStorage:', token);
      return token;
    }
  
    deleteToken() {
      localStorage.removeItem('token');
    }
  
    getUserPayload() {
      const token = this.getToken();
      if (token) {
        console.log('Token before decoding:', token);
        try {
          const userPayload = JSON.parse(atob(token.split('.')[1]));
          return userPayload;
        } catch (error) {
          console.error('Error decoding token:', error);
          return null;
        }
      } else {
        return null;
      }
    }
  
    isLoggedIn() {
      var userPayload = this.getUserPayload();
      if (userPayload)
        // return userPayload.exp > Date.now() / 1000;
        return true;
      else
        return false;
    }
}






 








